import themes from '../../config/themes';
import {
    CONFIG_RECEIVED,
    NAVIGATION_UPDATE, SET_ACTIONBUTTON, SET_CUSTOMER,
    SET_DEFAULT_PATH, SET_DIMENSIONS,
    SET_ERROR, SET_HOME,
    SET_LARGE_LAYOUT, SET_MOBILITE_REDUITE, SET_NAVITEM,
    SET_SHOWCOPYRIGHT,
    SET_SHOWHOWTO,
    SET_SUBNAVITEM,
    SET_SWIPEINDICATOR
} from '../actions';

const defaultState = {
    width: 1080,
    height: 1920,
    scale: 1,
    customer: undefined,
    screen: undefined,
    local: undefined,
    playerName: undefined,
    theme: {
        iconSet: ""
    },
    actionButton: 'copyright',
    action: '',
    mobiliteReduite: false,
    showHowto: false,
    showCopyright: false,
    swipeIndicator: true,
    defaultPath: '',
    home: true,
    largeLayout: true,
    layout: undefined,
    error: {
        number: undefined,
        message: undefined
    }
}

const config = (state = defaultState, action) => {
    switch (action.type) {

        case CONFIG_RECEIVED:
            let themeConfig = action.json.theme;
            themeConfig = { ...themeConfig, ...themes.global}
            return {
                ...state,
                location: action.json.location,
                sliderOrientation: action.json.sliderOrientation,
                navigation: action.json.navigation,
                theme: themeConfig,
                weatherStation: action.json.weatherStation,
                playerName: action.json.name }

        case NAVIGATION_UPDATE:
            if (action.json) {
                let subnav = [];
                action.json.forEach(elm => {
                    subnav.push({ "id": elm.id, "status": 2, "name": elm.name });
                });

                let obj = state.navigation.find(nav => nav.id === 'ereider');
                let index = state.navigation.indexOf(obj);
                state.navigation.fill(obj.subnavigation=subnav, index, index++);

                // Copy Array
                let ssubnav = state.navigation.slice();

                return { ...state, navigation: ssubnav, loading: false }

            } else {
                return state;
            }

        case SET_DIMENSIONS:
            let width, height;

            switch (action.layout) {
                case 1:
                    width = 1080;
                    height = 1920;
                    break;
                case 2:
                    width = 1024;
                    height = 1280;
                    break;
                case 3:
                    width = 1920;
                    height = 1080;
                    break;
                case 4:
                    width = 1080;
                    height = 1920;
                    break;
                default:
                    width = 1080;
                    height = 1920;
                    break;
            }

            let scale = 1;
            let h = Math.max(document.documentElement.clientHeight || 0);

            if (h > height) {
            scale = h / height;
            }

            return { ...state, width: width, height: height, layout: action.layout, scale: scale };

        case SET_CUSTOMER:
            return { ...state, customer: action.customer, local: action.local, screen: action.screen, loading: true };
    

        case SET_ACTIONBUTTON:
            let aButton = 'copyright'; // Until we activate MAIL Support
            return { ...state, actionButton: aButton, action: action.action};

        case SET_SHOWCOPYRIGHT:
            return { ...state, showCopyright: action.value};

        case SET_SHOWHOWTO:
            return { ...state, showHowto: action.value};

        case SET_SWIPEINDICATOR:
            return { ...state, swipeIndicator: action.state};

        case SET_NAVITEM:
            return { ...state, navItem: action.navItem};

        case SET_SUBNAVITEM:
            return { ...state, subNavItem: action.subNavItem};

        case SET_MOBILITE_REDUITE:
            return { ...state, mobiliteReduite: action.mobiliteReduite};
            
        case SET_DEFAULT_PATH:
            return { ...state, defaultPath: action.defaultPath};

        case SET_HOME:
            return { ...state, home: action.home};

        case SET_LARGE_LAYOUT:
            return { ...state, largeLayout: action.largeLayout};

        case SET_ERROR:
            let error = {
                number: action.error,
                message: action.message
            }
            return { ...state, error: error}
    

        default:
            return state;
    }
};

export default config;
