
import React, { useRef } from 'react';
import { useSelector } from 'react-redux';
import SlickSlider from "react-slick";
import "slick-carousel/slick/slick-theme.css";
import "slick-carousel/slick/slick.css";
import styled from 'styled-components';
//import videojs from "video.js";
import { Loading } from '../components/Layout';
//import VideoJS from './Player';


const SwiperItem = styled.div`
    .swiper-slide__container {
        max-height: ${props => props.theme.scale * props.theme.videoMaxHeight}px;          
        transition: max-height 0.5s;
        overflow: hidden;

        video {
            width: ${props => props.theme.scale * props.theme.videoWidth}px;    
            height: ${props => props.theme.scale * props.theme.videoHeight}px;    
        }
    }

    img {
        width: 100%;
        height: 100%;
    }

    h2 { 
        position: absolute;
        color: white; 
        bottom: ${props => props.theme.scale * 30}px;
        left: ${props => props.theme.scale * 55}px;
        right: ${props => props.theme.scale * 55}px;
        font-size: ${props => props.theme.scale * 40}px;
        font-family: 'MuseoSans-700';
        background-color: rgba(0,0,0,0.6);
        padding: ${props => props.theme.scale * 10}px;
    }
`;

const StyledSlickSlider = styled(SlickSlider)`
    &:focus {
        outline: none;
    } 
    .slick-slide {
        position: relative;
        width: ${props => props.theme.scale * props.theme.videoWidth}px;
        height: ${props => props.theme.scale * props.theme.videoHeight - 35}px;
        padding-top: ${props => props.theme.scale * 17}px;
        transition: padding 0.5s, opacity 0.5s, height 0.5s;
        overflow: hidden;
        opacity: 0.8;
    }

    .slick-current {
        padding-top: 0;
        opacity: 1;
        height: ${props => props.theme.scale * props.theme.videoHeight}px;
        .swiper-slide__container {            
            max-height: ${props => props.theme.scale * props.theme.videoHeight}px;
        }
    }
`;

/*
const Countdown = styled.div`
    height: ${props => props.theme.scale * 540}px;
    background: url('images/countdown/bg.jpg') top left no-repeat
`;

const Days = styled.div`
    color: white;
    position: absolute;
    top: ${props => props.theme.scale * 200}px;
    left: ${props => props.theme.scale * 0}px;
    width: ${props => props.theme.scale * 320}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 150}px;
    font-family: "MuseoSans-900", serif;

    span {
        display: block;
        font-size: ${props => props.theme.scale * 24}px;
        padding: ${props => props.theme.scale * 8}px 0;
    }
`;

const Hours = styled.div`
    color: white;
    position: absolute;
    top: ${props => props.theme.scale * 200}px;
    left: ${props => props.theme.scale * 320}px;
    width: ${props => props.theme.scale * 320}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 150}px;
    font-family: "MuseoSans-900", serif;

    span {
        display: block;
        font-size: ${props => props.theme.scale * 24}px;
        padding: ${props => props.theme.scale * 8}px 0;
    }
`;

const Minutes = styled.div`
    color: white;
    position: absolute;
    top: ${props => props.theme.scale * 200}px;
    left: ${props => props.theme.scale * 640}px;
    width: ${props => props.theme.scale * 320}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 150}px;
    font-family: "MuseoSans-900", serif;

    span {
        display: block;
        font-size: ${props => props.theme.scale * 24}px;
        padding: ${props => props.theme.scale * 8}px 0;
    }
`;

const Url = styled.div`
    color: black;
    position: absolute;
    bottom: ${props => props.theme.scale * 34}px;
    right: ${props => props.theme.scale * 0}px;
    width: ${props => props.theme.scale * 320}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 24}px;
    font-family: "MuseoSans-900", serif;
`;

const Logo22 = styled.div`
    color: black;
    position: absolute;
    top: ${props => props.theme.scale * 40}px;
    left: ${props => props.theme.scale * 30}px;
    width: ${props => props.theme.scale * 120}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 24}px;
    font-family: "MuseoSans-900", serif;
`;


const Partners = styled.div`
    color: black;
    position: absolute;
    top: ${props => props.theme.scale * 64}px;
    right: ${props => props.theme.scale * 60}px;
    width: ${props => props.theme.scale * 500}px;
    text-align: center;
    font-size: ${props => props.theme.scale * 24}px;
    font-family: "MuseoSans-900", serif;
`;
*/

const Slider = ({ className }) => {
    //const [days, setDays] = useState(undefined);
    //const [hours, setHours] = useState(undefined);
    //const [minutes, setMinutes] = useState(undefined);
    //const [seconds, setSeconds] = useState(undefined);
    const ads = useSelector(state => state.data.videoads);
    const scale = useSelector(state => state.config.scale);
    const layout = useSelector(state => state.config.layout);
    //const customer = useSelector(state => state.config.customer);
    const SliderTime = 10000; // every 10 seconds it switches to the next

    const mySlider = useRef(undefined);

    /*
    const _mk2chars = (str) => {
        return (str.length < 2) ? `0${str}` : str;
    }
    
    useEffect(() => {
        const setTime = () => {
            let now = dayjs();
    
            // 26.02.2022 à 16h00
            var d = dayjs('2022-02-26T15:00:00.000Z');
            //var d = dayjs('2022-02-22T06:26:00.000Z');
    
            let distance = d.valueOf() - now.valueOf();
    
            let days = Math.floor(distance / (1000 * 60 * 60 * 24));
            let hours = Math.floor((distance % (1000 * 60 * 60 * 24)) / (1000 * 60 * 60));
            let minutes = Math.floor((distance % (1000 * 60 * 60)) / (1000 * 60));
            let seconds = Math.floor((distance % (1000 * 60)) / 1000);
    
            setDays(_mk2chars(days));
            setHours(_mk2chars(hours));
            setMinutes(_mk2chars(minutes));
            setSeconds(_mk2chars(seconds));
        }
        
        setTime();

        const interval = setInterval(() => {
            setTime();
        }, 30000);
        
        return () => clearInterval(interval);
    }, []);
    */

    let padding;
    switch (layout) {
        case 2:
            padding = 72;
            break;

        default:
            padding = 60;
            break;
    }

    const params = {
        infinite: true,
        speed: 500, // Default 500
        slidesToShow: 1,
        slidesToScroll: 1,
        centerMode: true,
        centerPadding: padding * scale + 'px',
        dots: false,
        autoplay: true,
        autoplaySpeed: SliderTime,
        afterChange: function (index) {

            mySlider.current.slickPause();
            let video = document.querySelector('[data-index="' + index + '"] video');
            if (video) {
                var playPromise = video.play();

                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                }
                video.currentTime = 0;

                video.onended = function () {
                    mySlider.current.slickNext();
                };

            } else {
                mySlider.current.slickPlay();
            }
        },
        beforeChange: function (index, newIndex) {
            let video = document.querySelector('div[data-index="' + index + '"] video');
            if (video) {
                video.currentTime = 0;
                var playPromise = video.pause();

                if (playPromise !== undefined) {
                    playPromise.then(_ => {
                        // Automatic playback started!
                        // Show playing UI.
                    })
                        .catch(error => {
                            // Auto-play was prevented
                            // Show paused UI.
                        });
                };
            }
        }
    }

    //const playerRef = React.useRef(null);

    const handleInit = (slider) => {
        // Select the first video on the first slide (if it exists)
        let firstVideo = document.querySelector('div[data-index="0"] video');
    
        if (firstVideo) {
            // Play the first video
            firstVideo.play();
    
            // Move to the next slide when the video ends
            firstVideo.onended = function () {
                mySlider.current.slickNext();
            };
        } else {
            // Ensure the slider starts playing and moves to the next slide
            if (mySlider.current) {
                mySlider.current.slickPlay(); // Resume the autoplay for the slider
                mySlider.current.slickGoTo(1); // Move to the second slide (index 1)
            } else {
                console.warn("Slider not initialized or unavailable.");
            }
        }
    };
    

    const handleImgOnload = () => {
        setTimeout(() => { mySlider.current.slickGoTo(1); }, SliderTime);
    };

    let slides = [];

    if (ads) {
        slides = ads.map(
            (ad, i) => {
                if (ad.video) {

                    return (
                        <SwiperItem key={i}>
                            <div className="swiper-slide__container" id={"video" + i} isvideo="1" iscurrent="0">

                                <div className="swiper-slide__container" id={'video' + i}>
                                    <video poster={ad.placeholderurl} muted>
                                        <source src={ad.video} type="video/mp4" />
                                    </video>
                                </div>

                            </div>
                        </SwiperItem>
                    );
                } else {
                    return (
                        <SwiperItem key={i}>
                            {i === 0 ? <img src={ad.image} alt={ad.title} onLoad={handleImgOnload} /> : <img src={ad.image} alt={ad.title} />}
                        </SwiperItem>
                    )
                }
            }, [SwiperItem]
        );
    }

    /*
    if (customer === 'esch' && (days > 0 || hours > 0 || minutes > 0 || seconds > 0)) {
        // 26.02.2022 à 16h00
        let eschCountdown = <SwiperItem key="countdown1">
                <Logo22><img src={images.esch2022.logo_esch2022} alt="Esch 2022" /></Logo22>
                <Partners><img src={images.esch2022.logos_partenaires} alt="Esch 2022 Partner" /></Partners>
                <Countdown className="swiper-slide__container" id="countdown">
                    <Days><span>DAYS</span>{days}</Days>
                    <Hours><span>HOURS</span>{hours}</Hours>
                    <Minutes><span>MINUTES</span>{minutes}</Minutes>
                    <Url>esch2022.lu</Url>
                </Countdown>
        </SwiperItem>

        slides.push(eschCountdown);
    }
    */

    return (
        <React.Fragment>
            {ads ? (
                <StyledSlickSlider ref={mySlider} onInit={handleInit} className={className} {...params}>
                    {slides}
                </StyledSlickSlider>
            ) : (
                <Loading />
            )}
        </React.Fragment>
    );
}

export default Slider;